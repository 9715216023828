// Single Date Formatting
// -------------------------------------------------------------------------------------------------

function getSliderValueDefinitions() {
	var isPhone = window.dbk.isPhone;
	if (isPhone) {
		// is mobile
		return {
			day: 74,
			week: 115,
			month: 137,
			year: 161,
			decade: 185,
			century: 208,
		};
	} else {
		return {
			day: 148,
			week: 271,
			month: 342,
			year: 430,
			decade: 480,
			century: 500,
		};
	}
}

function horizonDaysToSlider(horizonDays) {
	var isPhone = window.dbk.isPhone;
	if (isPhone) {
		return mobileHorizonDaysToSlider(horizonDays);
	} else {
		return desktopHorizonDaysToSlider(horizonDays);
	}
}

function desktopHorizonDaysToSlider(horizonDays) {
	var sliderDefinition = horizonSliderDefinitions(null, horizonDays);
	var units =
		Math.ceil(
			Math.round((horizonDays / sliderDefinition.daysEstimate) * 10) / 10
		) - sliderDefinition.startingUnit;

	//Calculate the matching slider position for the specified number of days
	//This is done by dividing the number of slider steps by the number of positions for that segment
	//then multiplying that by the calculated daysEstimate units divided by the number of units in a group
	//and finally adding the number of slider steps for the starting point of the associated segment
	if (sliderDefinition.daysEstimate === 1 && units < 22) {
		// Day scale with 7-day increments
		return (46 / 3) * Math.max(0, Math.ceil(units / 7));
	} else if (sliderDefinition.daysEstimate === 1 && units >= 22) {
		// Day scale with 15-day increments
		return (82 / 5) * Math.max(0, Math.ceil((units - 30) / 15)) + 65;
	} else if (sliderDefinition.daysEstimate === 7) {
		// Week scale
		return (122 / 20) * Math.max(0, Math.ceil(units / 4)) + 148;
	} else if (sliderDefinition.daysEstimate === 30) {
		// Month scale
		return (71 / 71) * Math.max(0, units) + 271;
	} else if (sliderDefinition.daysEstimate === 365) {
		// Year scale
		return (87 / 87) * Math.max(0, units) + 342;
	} else if (sliderDefinition.daysEstimate === 365 * 10) {
		// Decade scale
		return (50 / 54) * Math.max(0, Math.ceil(units / 2)) + 430;
	} else {
		// Century scale
		return (20 / 20) * Math.max(0, Math.ceil(units / 4)) + 480;
	}
}

function mobileHorizonDaysToSlider(horizonDays) {
	var sliderValueDefinitions = getSliderValueDefinitions();
	var sliderDefinition = horizonSliderDefinitions(null, horizonDays);
	var units =
		Math.ceil(
			Math.round((horizonDays / sliderDefinition.daysEstimate) * 10) / 10
		) - sliderDefinition.startingUnit;

	var isPhone = window.dbk.isPhone;

	//Calculate the matching slider position for the specified number of days
	//This is done by dividing the number of slider steps by the number of positions for that segment
	//then multiplying that by the calculated daysEstimate units divided by the number of units in a group
	//and finally adding the number of slider steps for the starting point of the associated segment
	if (sliderDefinition.daysEstimate === 1 && units < 22) {
		// Day scale with 7-day increments
		return (46 / 3) * Math.max(0, Math.ceil(units / 7));
	} else if (sliderDefinition.daysEstimate === 1 && units >= 22) {
		// Day scale with 15-day increments
		return (19.5 / 1) * Math.max(0, Math.ceil((units - 30) / 15)) + 65;
	} else if (sliderDefinition.daysEstimate === 7) {
		// Week scale
		return (
			((sliderValueDefinitions.week - sliderValueDefinitions.day) /
				sliderDefinition.steps) *
				Math.max(0, Math.ceil(units / 4)) +
			sliderValueDefinitions.day
		);
	} else if (sliderDefinition.daysEstimate === 30) {
		// Month scale
		return (
			((sliderValueDefinitions.month - sliderValueDefinitions.week) /
				sliderDefinition.steps) *
				Math.max(0, units) +
			sliderValueDefinitions.week
		);
	} else if (sliderDefinition.daysEstimate === 365) {
		// Year scale
		return (
			((sliderValueDefinitions.year - sliderValueDefinitions.month) /
				sliderDefinition.steps) *
				Math.max(0, units) +
			sliderValueDefinitions.month
		);
	} else if (sliderDefinition.daysEstimate === 365 * 10) {
		// Decade scale
		return (
			((sliderValueDefinitions.decade - sliderValueDefinitions.year) /
				sliderDefinition.steps) *
				Math.max(0, units) +
			sliderValueDefinitions.year
		);
	} else {
		// Century scale
		return (
			((sliderValueDefinitions.century - sliderValueDefinitions.decade) /
				sliderDefinition.steps) *
				Math.max(0, units) +
			sliderValueDefinitions.decade
		);
	}
}
fc.horizonDaysToSlider = horizonDaysToSlider; // expose

function horizonSliderToDays(sliderValue, date) {
	var horizonSliderOptions = horizonSliderDefinitions(sliderValue);

	var startDate = moment(date);

	var endDate = moment(startDate).add(
		horizonSliderOptions.duration * horizonSliderOptions.typeOffset,
		horizonSliderOptions.type
	);
	var days = endDate.diff(startDate, 'days');
	return days;
}

fc.horizonSliderToDays = horizonSliderToDays; // expose

function resourceDaysClusterDefinitions(days) {
	const clusterType = days > 31 ? 'week' : 'day';
	const clusterOptions = {
		type: clusterType,
		duration: clusterType === 'week' ? days / 7 : days,
		columnCount: clusterType === 'week' ? days / 7 : days,
		typeOffset: 1,
		grouping: clusterType,
	};

	return clusterOptions;
}

fc.resourceDaysClusterDefinitions = resourceDaysClusterDefinitions; // expose

function horizonSliderDefinitions(sliderValue, horizonDays) {
	var isPhone = window.dbk.isPhone;

	return isPhone
		? mobileHorizonDefinitions(sliderValue, horizonDays)
		: desktopHorizonDefinitions(sliderValue, horizonDays);
}

function mobileHorizonDefinitions(sliderValue, horizonDays) {
	var clusterType;
	var clusterDuration;
	var daysEstimate;
	var startingUnit;
	var label;
	var sliderDisplayValue;
	var clusterTypeOffset = 1;

	var sliderValueDefinitions = getSliderValueDefinitions();

	clusterDuration = horizonSliderToColumns(sliderValue);

	if (
		(sliderValue && sliderValue < sliderValueDefinitions.day) ||
		(horizonDays && horizonDays <= 30)
	) {
		// Days
		clusterType = 'day';
		grouping = 'day';

		daysEstimate = 1;
		startingUnit = 0;
		steps = 4;

		label = clusterDuration === 1 ? 'day' : 'days';
	} else if (
		(sliderValue && sliderValue < sliderValueDefinitions.week) ||
		(horizonDays && horizonDays <= 32 * 7)
	) {
		// Weeks
		clusterType = 'week';
		grouping = 'week';

		daysEstimate = 7;
		startingUnit = 8;
		steps = 7;

		label = clusterDuration === 1 ? 'week' : 'weeks';
	} else if (
		(sliderValue && sliderValue < sliderValueDefinitions.month) ||
		(horizonDays && horizonDays <= 30 * 30)
	) {
		// Months
		clusterType = 'month';
		grouping = 'month';

		daysEstimate = 30;
		startingUnit = 9;
		steps = 22;

		label = clusterDuration === 1 ? 'month' : 'months';
	} else if (
		(sliderValue && sliderValue < sliderValueDefinitions.year) ||
		(horizonDays && horizonDays <= 30 * 365)
	) {
		// Years
		clusterType = 'year';
		grouping = 'year';

		daysEstimate = 365;
		startingUnit = 7;
		steps = 24;

		label = clusterDuration === 1 ? 'year' : 'years';
	} else if (
		(sliderValue && sliderValue < sliderValueDefinitions.decade) ||
		(horizonDays && horizonDays <= 30 * 10 * 365)
	) {
		// Decades
		clusterType = 'year';
		grouping = 'decade';
		clusterTypeOffset = 10;

		daysEstimate = 365 * clusterTypeOffset;
		startingUnit = 7;
		steps = 24;

		label = clusterDuration === 1 ? 'decade' : 'decades';
	} else {
		// Centuries
		clusterType = 'year';
		grouping = 'century';
		clusterTypeOffset = 100;

		daysEstimate = 365 * clusterTypeOffset;
		startingUnit = 7;
		steps = 24;

		label = clusterDuration === 1 ? 'century' : 'centuries';
	}

	return {
		type: clusterType,
		grouping: grouping,
		daysEstimate: daysEstimate,
		duration: clusterDuration,
		typeOffset: clusterTypeOffset,
		steps: steps,
		label: label,
		startingUnit: startingUnit,
	};
}

function desktopHorizonDefinitions(sliderValue, horizonDays) {
	var clusterType;
	var clusterDuration;
	var daysEstimate;
	var startingUnit;
	var label;
	var sliderDisplayValue;
	var clusterTypeOffset = 1;

	var sliderValueDefinitions = getSliderValueDefinitions();

	clusterDuration = horizonSliderToColumns(sliderValue);

	if (
		(sliderValue && sliderValue < 148) ||
		(horizonDays && horizonDays <= 90)
	) {
		// Days
		clusterType = 'day';
		grouping = 'day';

		daysEstimate = 1;
		startingUnit = 0;
		steps = 8;

		label = clusterDuration === 1 ? 'day' : 'days';
	} else if (
		(sliderValue && sliderValue < 271) ||
		(horizonDays && horizonDays <= 96 * 7)
	) {
		// Weeks
		clusterType = 'week';
		grouping = 'week';

		daysEstimate = 7;
		startingUnit = 16;
		steps = 20; // This needs to be steps divided by 4. Actual steps are 81 so 20 is rounded down after division;

		label = clusterDuration === 1 ? 'week' : 'weeks';
	} else if (
		(sliderValue && sliderValue < 342) ||
		(horizonDays && horizonDays <= 94 * 30)
	) {
		// Months
		clusterType = 'month';
		grouping = 'month';

		daysEstimate = 30;
		startingUnit = 24;
		steps = 71;

		label = clusterDuration === 1 ? 'month' : 'months';
	} else if (
		(sliderValue && sliderValue < 430) ||
		(horizonDays && horizonDays <= 97 * 365)
	) {
		// Years
		clusterType = 'year';
		grouping = 'year';

		daysEstimate = 365;
		startingUnit = 10;
		steps = 87;

		label = clusterDuration === 1 ? 'year' : 'years';
	} else if (
		(sliderValue && sliderValue < 480) ||
		(horizonDays && horizonDays <= 117 * 10 * 365)
	) {
		// Decades
		clusterType = 'year';
		grouping = 'decade';
		clusterTypeOffset = 10;

		daysEstimate = 365 * clusterTypeOffset;
		startingUnit = 11;
		steps = 54;

		label = clusterDuration === 1 ? 'decade' : 'decades';
	} else {
		// Centuries
		clusterType = 'year';
		grouping = 'century';
		clusterTypeOffset = 100;

		daysEstimate = 365 * clusterTypeOffset;
		startingUnit = 16;
		steps = 54;

		label = clusterDuration === 1 ? 'century' : 'centuries';
	}

	return {
		type: clusterType,
		grouping: grouping,
		daysEstimate: daysEstimate,
		duration: clusterDuration,
		typeOffset: clusterTypeOffset,
		steps: steps,
		label: label,
		startingUnit: startingUnit,
	};
}

fc.horizonSliderDefinitions = horizonSliderDefinitions; // expose

function horizonSliderToColumns(sliderValue) {
	var isPhone = window.dbk.isPhone;

	return isPhone
		? mobileHorizonToColumns(sliderValue)
		: desktopHorizonToColumns(sliderValue);
}

function mobileHorizonToColumns(sliderValue) {
	var returnValue;
	var offset = 0;

	var sliderValueDefinitions = getSliderValueDefinitions();

	if (sliderValue < sliderValueDefinitions.day) {
		// Day scale
		if (sliderValue < 18.5) {
			return 7;
		} else if (sliderValue < 37) {
			return 14;
		} else if (sliderValue < 55.5) {
			return 21;
		} else if (sliderValue < 74) {
			return 30;
		}
	} else if (sliderValue < sliderValueDefinitions.week) {
		// Week scale
		returnValue = Math.ceil((sliderValue - 66) / 1.5);
		offset = returnValue % 4 > 0 ? 4 - (returnValue % 4) : 0; //See how far off we are from the nearest week
		return returnValue + offset;
	} else if (sliderValue < sliderValueDefinitions.month) {
		// Month scale
		returnValue = Math.ceil(
			sliderValue - (sliderValueDefinitions.week - 9)
		);
		return returnValue;
	} else if (sliderValue < sliderValueDefinitions.year) {
		// Year scale
		returnValue = Math.ceil(
			sliderValue - (sliderValueDefinitions.month - 7)
		);
		return returnValue;
	} else if (sliderValue < sliderValueDefinitions.decade) {
		// Decade scale
		returnValue = Math.ceil(
			sliderValue - (sliderValueDefinitions.year - 7)
		);
		return returnValue;
	} else {
		// Century scale
		returnValue = Math.ceil(
			sliderValue - (sliderValueDefinitions.decade - 7)
		);
		return returnValue;
	}
}

function desktopHorizonToColumns(sliderValue) {
	var returnValue;
	var offset = 0;

	var sliderValueDefinitions = getSliderValueDefinitions();

	if (sliderValue < 148) {
		// Day scale
		if (sliderValue < 18.5) {
			return 7;
		} else if (sliderValue < 37) {
			return 14;
		} else if (sliderValue < 55.5) {
			return 21;
		} else if (sliderValue < 74) {
			return 30;
		} else if (sliderValue < 92.5) {
			return 45;
		} else if (sliderValue < 111) {
			return 60;
		} else if (sliderValue < 129.5) {
			return 75;
		} else if (sliderValue < 148) {
			return 90;
		}
	} else if (sliderValue < 271) {
		// Week scale
		returnValue = Math.ceil((sliderValue - 129) / 1.5);
		offset = returnValue % 4 > 0 ? 4 - (returnValue % 4) : 0; //See how far off we are from the nearest week
		return returnValue + offset;
	} else if (sliderValue < 342) {
		// Month scale
		returnValue = Math.ceil(sliderValue - 247);
		return returnValue;
	} else if (sliderValue < 430) {
		// Year scale
		returnValue = Math.ceil(sliderValue - 332);
		return returnValue;
	} else if (sliderValue < 480) {
		// Decade scale
		returnValue = Math.ceil((sliderValue - 425) * 2.16);
		return returnValue;
	} else {
		// Century scale
		returnValue = Math.ceil((sliderValue - 476) * 4);
		return returnValue;
	}
}

fc.horizonSliderToColumns = horizonSliderToColumns; // expose

// call this if you want Moment's original format method to be used
function momentFormat(mom, formatStr) {
	return moment.fn.format.call(mom, formatStr);
}

function getFormatFromMatrix(formatObj, width, returnDateFormat) {
	var availableSizes = [];
	var result;
	if (!formatObj) {
		return '';
	}

	//Solution to calendar object not being sized yet - Generally occurs in FileMaker on Windows on startup
	width = width > 0 ? width : 1;

	//Find available sizes. This might not be in order so we want to track this forst and then work out the rest later
	for (var property in formatObj) {
		if (formatObj[property].breakPoint < width) {
			availableSizes.push(property);
		}
	}

	//Pick the best format string based on size.
	for (var i = 0; i < availableSizes.length; i++) {
		if (
			!result ||
			formatObj[availableSizes[i]].breakPoint > result.breakPoint
		) {
			result = formatObj[availableSizes[i]];
		}
	}

	return returnDateFormat ? result.date : result.format;
}

// Formats `date` with a Moment formatting string, but allow our non-zero areas and
// additional token.
function formatDate(date, formatStr) {
	return formatDateWithChunks(date, getFormatStringChunks(formatStr));
}

function formatLocalizedDate(date, formatStr) {
	return date.format(localizeDateFormat(formatStr));
}

function localizeDateFormat(formatStr) {
	var output = '';
	var localizedTokens = [];
	var compareDate;
	var localizedSeparator;
	var date = moment().month(0).date(15); // set a specific date so we don't get the month and day as the same number ie. 01/01/2015
	var unitMap = {
		Y: 'YYYY',
		M: 'MM',
		D: 'DD',
	};

	var separators = ['/', '-', '.'];
	var localizedString = date.format('L');
	var formatChunks = getFormatStringChunks(formatStr);
	var localizedChunks = localizedString.split(/[ -\/\.]+/);

	//Make sure that we have localized chnunks, we might not for example when using Chinese date formats
	if (localizedChunks && localizedChunks.length === 1) {
		for (var i = 0; i < localizedString.length; i++) {
			if (isNaN(Number(localizedString[i]))) {
				localizedString = localizedString.replace(
					localizedString[i],
					'-'
				);
			}
		}

		localizedChunks = localizedString.split(/[ -\/\.]+/);
		//Ensure two digit minimum dates
		for (var i = 0; i < localizedChunks.length; i++) {
			if (localizedChunks[i].length === 1) {
				localizedChunks[i] = 0 + localizedChunks[i];
			}
		}
	}
	//Loop through our localized date array checking for matches to the chunks
	for (var i = 0; i < localizedChunks.length; i++) {
		//Loop through our formatted chunks and build our new date
		for (var ii = 0; ii < formatChunks.length; ii++) {
			if (formatChunks[ii].token) {
				compareDate = date.format(
					unitMap[formatChunks[ii].token.charAt(0)]
				);
				if (localizedChunks[i] === compareDate) {
					localizedTokens.push(formatChunks[ii].token);
				}
			}
		}
	}

	//If we have not come up with any localized tokens then short circuit and return original passed in format
	if (!localizedTokens.length) {
		return formatStr;
	}

	//Determine the localized separator being used
	for (var sepCount = 0; sepCount < separators.length; sepCount++) {
		if (localizedString.indexOf(separators[sepCount]) > -1) {
			localizedSeparator = separators[sepCount];
			break;
		}
	}

	//Build result
	var chunkTokenPosition = 0;
	for (
		var chunkPosition = 0;
		chunkPosition < formatChunks.length;
		chunkPosition++
	) {
		if (formatChunks[chunkPosition].token) {
			//If this was a match with the custom localized format then write that localized data
			if (unitMap[formatChunks[chunkPosition].token.charAt(0)]) {
				output += localizedTokens[chunkTokenPosition];
				chunkTokenPosition++;
			}
			//If this didn't need to be changed for localization then just write as is
			else {
				output += formatChunks[chunkPosition].token;
			}
		}
		//This is a separator or not recognized as a date format object
		else {
			//Check if we are a separator and replace with new appropriate separator

			output += formatChunks[chunkPosition].replace(
				/[-\/\.]+/,
				localizedSeparator
			);
		}
	}
	return output;
}
fc.localizeDateFormat = localizeDateFormat; // expose

function formatDateWithChunks(date, chunks) {
	var s = '';
	var i;

	for (i = 0; i < chunks.length; i++) {
		s += formatDateWithChunk(date, chunks[i]);
	}

	return s;
}

// addition formatting tokens we want recognized
var tokenOverrides = {
	t: function (date) {
		// "a" or "p"
		return momentFormat(date, 'a').charAt(0);
	},
	T: function (date) {
		// "A" or "P"
		return momentFormat(date, 'A').charAt(0);
	},
};

function formatDateWithChunk(date, chunk) {
	var token;
	var maybeStr;

	if (typeof chunk === 'string') {
		// a literal string
		return chunk;
	} else if ((token = chunk.token)) {
		// a token, like "YYYY"
		if (tokenOverrides[token]) {
			return tokenOverrides[token](date); // use our custom token
		}
		return momentFormat(date, token);
	} else if (chunk.maybe) {
		// a grouping of other chunks that must be non-zero
		maybeStr = formatDateWithChunks(date, chunk.maybe);
		if (maybeStr.match(/[1-9]/)) {
			return maybeStr;
		}
	}

	return '';
}

// Date Range Formatting
// -------------------------------------------------------------------------------------------------
// TODO: make it work with timezone offset

// Using a formatting string meant for a single date, generate a range string, like
// "Sep 2 - 9 2013", that intelligently inserts a separator where the dates differ.
// If the dates are the same as far as the format string is concerned, just return a single
// rendering of one date, without any separator.
function formatRange(date1, date2, formatStr, separator, isRTL) {
	//Customized for seedcode
	if (formatStr.indexOf('w') !== -1) {
		return date1.format(formatStr);
	}
	//End customize

	var localeData;

	date1 = fc.moment.parseZone(date1);
	date2 = fc.moment.parseZone(date2);

	localeData = (date1.localeData || date1.lang).call(date1); // works with moment-pre-2.8

	// Expand localized format strings, like "LL" -> "MMMM D YYYY"
	var localizedStr = formatStr.replace(/[^l^L]/g, '');

	if (localizedStr) {
		formatStr = formatStr.replace(
			localizedStr,
			localeData.longDateFormat(localizedStr)
		);
	}
	// BTW, this is not important for `formatDate` because it is impossible to put custom tokens
	// or non-zero areas in Moment's localized format strings.

	separator = separator || ' - ';

	return formatRangeWithChunks(
		date1,
		date2,
		getFormatStringChunks(formatStr),
		separator,
		isRTL
	);
}
fc.formatRange = formatRange; // expose

function formatRangeWithChunks(date1, date2, chunks, separator, isRTL) {
	var chunkStr; // the rendering of the chunk
	var leftI;
	var leftStr = '';
	var rightI;
	var rightStr = '';
	var middleI;
	var middleStr1 = '';
	var middleStr2 = '';
	var middleStr = '';

	// Start at the leftmost side of the formatting string and continue until you hit a token
	// that is not the same between dates.
	for (leftI = 0; leftI < chunks.length; leftI++) {
		chunkStr = formatSimilarChunk(date1, date2, chunks[leftI]);
		if (chunkStr === false) {
			break;
		}
		leftStr += chunkStr;
	}

	// Similarly, start at the rightmost side of the formatting string and move left
	for (rightI = chunks.length - 1; rightI > leftI; rightI--) {
		chunkStr = formatSimilarChunk(date1, date2, chunks[rightI]);
		if (chunkStr === false) {
			break;
		}
		rightStr = chunkStr + rightStr;
	}

	// The area in the middle is different for both of the dates.
	// Collect them distinctly so we can jam them together later.
	for (middleI = leftI; middleI <= rightI; middleI++) {
		middleStr1 += formatDateWithChunk(date1, chunks[middleI]);
		middleStr2 += formatDateWithChunk(date2, chunks[middleI]);
	}

	if (middleStr1 || middleStr2) {
		if (isRTL) {
			middleStr = middleStr2 + separator + middleStr1;
		} else {
			middleStr = middleStr1 + separator + middleStr2;
		}
	}

	return leftStr + middleStr + rightStr;
}

var similarUnitMap = {
	Y: 'year',
	M: 'month',
	D: 'day', // day of month
	d: 'day', // day of week
	// prevents a separator between anything time-related...
	A: 'second', // AM/PM
	a: 'second', // am/pm
	T: 'second', // A/P
	t: 'second', // a/p
	H: 'second', // hour (24)
	h: 'second', // hour (12)
	m: 'second', // minute
	s: 'second', // second
};
// TODO: week maybe?

// Given a formatting chunk, and given that both dates are similar in the regard the
// formatting chunk is concerned, format date1 against `chunk`. Otherwise, return `false`.
function formatSimilarChunk(date1, date2, chunk) {
	var token;
	var unit;

	if (typeof chunk === 'string') {
		// a literal string
		return chunk;
	} else if ((token = chunk.token)) {
		unit = similarUnitMap[token.charAt(0)];
		// are the dates the same for this unit of measurement?
		//Currently we are not displaying years for the first date if they are different to save on space in the header. Won't work when we get to multi-year views.
		// if (unit && date1.isSame(date2, unit) || unit === 'year') {
		if (unit && date1.isSame(date2, unit)) {
			return momentFormat(date2, token); // would be the same if we used `date1`
			// BTW, don't support custom tokens
		}
		//Original comparison. Re-enable this to support 2 different years in date header to support multi-year views.
		// if (unit && date1.isSame(date2, unit)) {
		// 	return momentFormat(date1, token); // would be the same if we used `date2`
		// 	// BTW, don't support custom tokens
		// }
	}

	return false; // the chunk is NOT the same for the two dates
	// BTW, don't support splitting on non-zero areas
}

// Chunking Utils
// -------------------------------------------------------------------------------------------------

var formatStringChunkCache = {};

function getFormatStringChunks(formatStr) {
	if (formatStr in formatStringChunkCache) {
		return formatStringChunkCache[formatStr];
	}
	return (formatStringChunkCache[formatStr] = chunkFormatString(formatStr));
}

// Break the formatting string into an array of chunks
function chunkFormatString(formatStr) {
	var chunks = [];
	var chunker = /\[([^\]]*)\]|\(([^\)]*)\)|(LT|(\w)\4*o?)|([^\w\[\(]+)/g; // TODO: more descrimination
	var match;

	while ((match = chunker.exec(formatStr))) {
		if (match[1]) {
			// a literal string instead [ ... ]
			chunks.push(match[1]);
		} else if (match[2]) {
			// non-zero formatting inside ( ... )
			chunks.push({maybe: chunkFormatString(match[2])});
		} else if (match[3]) {
			// a formatting token
			chunks.push({token: match[3]});
		} else if (match[5]) {
			// an unenclosed literal string
			chunks.push(match[5]);
		}
	}

	return chunks;
}

function calculateWeekNumber(calc, mom, dayCnt) {
	//Check if we are working in a date span and adjust based on that.
	if (dayCnt && dayCnt > 1) {
		mom = moment(mom).add(Math.floor(dayCnt / 2), 'days');
	}

	if (typeof calc === 'function') {
		return calc(mom);
	} else if (calc === 'local') {
		return mom.week();
	} else if (calc.toUpperCase() === 'ISO') {
		return mom.isoWeek();
	}
}
fc.calculateWeekNumber = calculateWeekNumber; // expose
